/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				var html = $('html');

				$('.mobile-search').on('focus', function() {
				$(this).closest('.mobile-search-form').addClass('focus-active');
				});

				$('.mobile-search').on('focusout', function() {
				$(this).closest('.mobile-search-form').removeClass('focus-active');
				});

				$('.pb_slider-container').slick({
					slidesToScroll: 1,
					slidesToShow: 3,
					arrows: false,
					autoplay: false,
					pagination: false,
					responsive: [{
									breakpoint: 992,
									settings: {
									slidesToShow: 2,
									},
						breakpoint: 769,
						settings: {
						slidesToShow: 1
						}
					}]
				});

				$('.fw-slider--wrapper').slick({
					autoplay: false,
          			adaptiveHeight: true,
					prevArrow: $('.prev-arrow'),
					nextArrow: $('.next-arrow')
				});

				$('.header--menu').on('click', function() {
						html.removeClass('loading').toggleClass('nav-open');
				});

				$('.nav-mobile-primary-close--wrapper').on('click', function() {
						html.removeClass('nav-open');
				});

				$(document).on('facetwp-loaded', function() {
					$('html, body').animate({ scrollTop: 0 }, 500);
					$('.facetwp-facet').each(function() {
						var $facet = $(this);
						var facet_name = $facet.attr('data-name');
						var facet_label = FWP.settings.labels[facet_name];
			
						if ($facet.closest('.facet-wrap').length < 1) {
							$facet.wrap('<div class="facet-wrap"></div>');
							$facet.before('<h3 class="facet-label">' + facet_label + '</h3>');
						}
					});
					// Init variation forms on archive pages when page or category changes
					$('.variations_form').each(function(){
						$(this).wc_variation_form();
					});
				});
				
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$('iframe[src*="youtube"]').parent().fitVids();
				$('.latest-products--wrapper').slick({
					slidesToShow: 2,
					slidesToScroll: 1,
					autoplay: false,
					animate: "swing",
					nextArrow: $('.latest-prod-arrow-next'),
					prevArrow: $('.latest-prod-arrow-prev'),
					dots: true,
					appendDots: $('.latest-prod-pagination'),
					customPaging: function(slick,index) {
						var index = index + 1
						if( index <= 10) {
							var index = "0".concat(index);
						}
						return '<a>' + (index) + '</a>';
					},
					responsive: [
						{
							breakpoint: 1220,
							settings: {
								slidesToShow: 1
							}
						}
					]
				});
				$('#menu-mobile-menu .menu-item-has-children').on('click', function(e){
					e.preventDefault();
					$(this).toggleClass('active');
				})

				$('#searchform').on('keypress', function(e) {
					if(e.which == 13) {
						$('#searchform > input[type="submit"]').click();
					}
				});
				$('.mobile-searchbox-input').on('keypress', function(e) {
					if(e.which == 13) {
						if( $(this).val().length > 0 ){
							$('.mobile-searchbox-input').submit();
						}
					}
				});
				
				function triggerActive() {
					$('.product-grid--container').toggleClass('active');
				}

				// Footer Menu Accordian
				$('body').on('click', '.js-footer-accordion-trigger', function (e) {
					e.preventDefault();
					var $accordion = $(this).closest('.footer-accordion');
					var $accordionContent = $accordion.find('.footer-accordion__content');
					if ($accordion.hasClass('active')) {
						$accordion.removeClass('active');
						$accordionContent.slideUp(350);
					} else {
						$accordion.addClass('active');
						$accordionContent.slideDown(350);
					}
				});

				// Add to Cart AJAX
				$( document ).on( 'click', 'body:not(.single-product) form.cart .button', function(e) { 	
					e.preventDefault();
					var add_to_cart_form = $( this ).closest( 'form.cart' )
					var product_id = add_to_cart_form.find( '#product-id' ).val();
					var quantity = add_to_cart_form.find( 'input[type=number]' ).val();
					var var_id = '';
					var is_variation = add_to_cart_form.hasClass( 'variations_form' );
					var attributes = {};
					// if variation add to cart
					if (is_variation) {
						product_id = add_to_cart_form.find( 'input[name=product_id]' ).val();
						var var_id = add_to_cart_form.find( 'input[name=variation_id]' ).val();

						var empty_attribute = false;
						// get variation attributes 
						add_to_cart_form.find('.variation-attribute select').each(function( index, elem ) {
							var attribute_name = $(this).data('attribute_name');
							var attribute_value = $(this).val();
	
							if (!attribute_value || attribute_value.length === 0 ) {
								empty_attribute = true;
							}
	
							attributes[attribute_name] = attribute_value;
						});

						// do not continue if we have empty attributes
						if (empty_attribute) {
							return;
						}
					} 

					// prepare data for AJAX
					var data = {
						action: 'woocommerce_ajax_add_to_cart',
						product_id: product_id,
						quantity: quantity,
						product_sku: '',
						variation_id: var_id,
						variation: JSON.stringify(attributes)
					};

					$.ajax({
						type: 'post',
						url: wc_add_to_cart_params.ajax_url,
						data: data,
						beforeSend: function (response) {
							$(add_to_cart_form).find('.button').addClass('loading');
						},
						complete: function (response) {
							$(add_to_cart_form).find('.button').removeClass('loading') ;
						},
						success: function (response) {
							if (response.error == true) {
								window.location = response.product_url;
								return;
							} else {
								$(add_to_cart_form).find('.button').addClass('AddedtoCart') ;
								$(add_to_cart_form).find('.hidden_view_cart').addClass('active') ;
								$(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
							}
						},
					});

				});

				// Biltong Modal
				// Open modal
				jQuery('.open-biltong-coupon-modal').on('click', function(e) {
					e.preventDefault();
					jQuery('.biltong-coupon-modal').addClass('open');
					jQuery('.sc-modal-blocker').addClass('open');
				});

				// Close modal
				jQuery('.sc-modal .close-modal').on('click', function(e) {
					e.preventDefault();
					jQuery('.biltong-coupon-modal').removeClass('open');
					jQuery('.sc-modal-blocker').removeClass('open');
				});
				// Close modal when clicking blocker/background
				jQuery('.sc-modal-blocker').on('click', function(e) {
					e.preventDefault();
					jQuery('.biltong-coupon-modal').removeClass('open');
					jQuery('.sc-modal-blocker').removeClass('open');
				});


				$(".input-text.qty.text").bind('keyup mouseup', function () { var value = $(this).val();
					$(".product_quantity").val(value)
				});

				$(document).on('added_to_cart', function() {
					setTimeout(triggerActive, 1000);
					setTimeout(triggerActive, 2500);
				});

				$(document).on('facetwp-refresh', function() {
					triggerActive();
					$.fn.matchHeight._update();
				});

				$(document).on('facetwp-loaded', function() {

					triggerActive();

					var elementList = document.querySelectorAll('.facet-wrap');
					for (i = 0; i < elementList.length; ++i) {
						var x = elementList[i];
						var n = x.querySelector('.facetwp-facet')
						if(n.hasChildNodes() === false) {
							x.className += ' d-none';
						}
					  }
					  $.fn.matchHeight._update();

				});

				$('.filter-btn-toggle').on('click', function(e){
					e.preventDefault();
					console.log('clicked');
					$('.product-sidebar--container').toggleClass('open');
				});

				$('.inner-product .media-content img').matchHeight();
				$('.slider-container').slick({
					infinite: true,
					fade: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					cssEase: 'linear',
					arrows: false,
					appendDots: $('.dots-container'),
					dots: true,
					customPaging: function (slick, index) {
						return '<div class="slider-icon"></div>';
					},

				});

				$('.close-self').on('click', function(){
					$('.close-self').parents('.top-cta').slideUp();
				});

				$('.cart-remove-link').on('click', function(e) {
					e.preventDefault();
					var parent = $(this).parent('.item');
					var key = $(this).data('product-key');
						$.ajax({
							url: 'wp-admin/admin-ajax.php',
							type: "POST",
							dataType: 'json',
							data: { 
								'action' : 'remove_item_from_cart',
								'key' : key
							},
							success: function(data) {
								var x = $.parseJSON(data);
								$(parent).hide('slow');
								// console.log(data);
								$('.item-count').text((x - 1) +' Items');
							}
						});
				});

				$('.gfield_select').select2({
					width: '100%',
					minimumResultsForSearch: Infinity
				});

				$('.trust-slider--container').slick({
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false,
					dots: false,
					responsive: [{
						breakpoint: 992,
						settings: {
						  slidesToShow: 1,
						}
          }],
        		});

				$(document).bind('gform_confirmation_loaded', function(e){
					setTimeout(function() {
						$('.product-enquiry').removeClass('active');
						$('.background-blocker').removeClass('active');
					}, 2000);
				});

				$('.enq-toggle').click(function(e) {
					$('.product-enquiry').toggleClass('active');
					$('.background-blocker').toggleClass('active');
				});

				$('.background-blocker').click(function(e) {
					$('.product-enquiry').removeClass('active');
					$('.background-blocker').removeClass('active');
				});

				 $('.login-trigger').on('click', function(e){
				 		$(this).parent().addClass('active');
				 		$('.login-child').addClass('active');
				 });

				$('.close-signup').on('click', function(e) {
					$(this).parent().removeClass('active');
				$('.black-out.login').removeClass('active');
				});

				$('.timeline-container').slick({
					slidesToShow: 4.5,
					slidesToScroll: 1,
					infinite: false,
					arrows: true,
					nextArrow: $('.next'),
					prevArrow: $('.prev'),
					dots: false,
					responsive: [{
						breakpoint: 1200,
						settings: {
						  slidesToShow: 3,
						}
					  }, {
						breakpoint: 992,
						settings: {
						  slidesToShow: 2,
						}
					}, {
						breakpoint: 786,
						settings: {
							slidesToShow: 1
						}
					  }]
				});

				$('.timeline-container').on('setPosition', function(event, slick) {
					var slideCount = slick.slideCount;
					if( (slideCount - 4) <= 1 ) {
						slideCount = 2
					} else {
						slideCount = slideCount;
					}
					var width = ($('.timeline-pagination').width() ) / slideCount;
					$('.indicator').css('width', width );
				});

				$('.timeline-container').on('afterChange', function(event, slick, currentSlide){
					var slideCount = slick.slideCount;
					
					if( (slideCount - 4) <= 1 ) {
						slideCount = 2;
					} else {
						slideCount = slideCount;
					}

					var pos = ( $('.timeline-pagination').width() ) / slideCount;
					if(currentSlide > 0 ) {

						var currentPos = pos * currentSlide;
					} else {
						var currentPos = 0;
					}
					// $('.indicator').css('left', currentPos);
					$('.indicator').animate({
						left: currentPos,
						opacity: 1
					  }, 500 );

				});

				$('.add_to_cart_ajax').on('click', function(e){
					e.preventDefault(); 
					var product_id = $(this).val();
					var itemParent = $(this).closest('article');
					var product_qty = $(itemParent).find('input[type="number"]').val();

					var data = {
						action: 'woocommerce_ajax_add_to_cart',
						product_id: product_id,
						product_sku: '',
						quantity: product_qty,
						variation_id: '0',
					};

					$.ajax({
							url: wc_add_to_cart_params.ajax_url,
							type: "POST",
							data: data,
							success: function(data) {
								console.log(data);
							},
							error: function(err) {
								console.log(err);
							}
						});
					
				});

				$('.button.increment').on('click', function(e){
					e.preventDefault();
					if( $(this).hasClass('minus') ) {
						var x = parseInt( $(this).siblings('input.qty').val() - 1);
						$(this).siblings('input.qty').val(x);
					}
					if( $(this).hasClass('plus') ) {
						var x = parseInt($(this).siblings('input.qty').val());
						x++;
						$(this).siblings('input.qty').val(x);
					}
				});

				$('.slider-single').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: false,
					adaptiveHeight: true,
					infinite: false,
				   	useTransform: true,
					speed: 400,
					cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
					responsive: [{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							autoplay: true
						}
					}]
				});
			   
				$('.slider-nav')
					.on('init', function(event, slick) {
						$('.slider-nav .slick-slide.slick-current').addClass('is-active');
					})
					.slick({
						slidesToShow: 4,
						slidesToScroll: 4,
						dots: false,
						arrows: false,
						focusOnSelect: false,
						infinite: false,
						responsive: [{
							breakpoint: 1024,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 4,
							}
						}, {
							breakpoint: 640,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 4,
						   }
						}, {
							breakpoint: 420,
							settings: {
								slidesToShow: 0,
								slidesToScroll: 0,
					   }
						}]
					});
					
				$('.slider-single').on('afterChange', function(event, slick, currentSlide) {
					$('.slider-nav').slick('slickGoTo', currentSlide);
					var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
					$('.slider-nav .slick-slide.is-active').removeClass('is-active');
					$(currrentNavSlideElem).addClass('is-active');
				});

				$('.region-selector-switch').click(function() {
						console.log('fired');
				});
			   
				$('.slider-nav').on('click', '.slick-slide', function(event) {
					event.preventDefault();
					var goToSingleSlide = $(this).data('slick-index');
			   
					$('.slider-single').slick('slickGoTo', goToSingleSlide);
				});

				$('.blockquote--wrapper').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					nextArrow: $('.arrow-next'),
					prevArrow: $('.arrow-prev'),
					dots: true,
					appendDots: $('.pagination'),
					customPaging: function(slick,index) {
						var index = index + 1
						if( index <= 10) {
							var index = "0".concat(index);
						}
						return '<a>' + (index) + '</a>';
					}
				});	

				$('.recommended-products-slider').slick({
					slidesToScroll: 1,
					slidesToShow: 4,
					arrows: false,
					dots: false,
					responsive: [
						{
							breakpoint: 1400,
							settings: {
								slidesToShow: 3
							}
						},
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 786,
							settings: {
								slidesToShow: 1
							}
						},
						{
							breakpoint: 486,
							settings: {
								slidesToShow: 1
							}
						},
					]
				});

				$('.kids-slider').slick({
					arrows: false,
					autoplay: true,
					dots: false,
					nextArrow: $('.kids-next'),
					prevArrow: $('.kids-prev'),
				});

				// $(function () {
				// 	$('select').selectpicker();
				// });
			}
		},
		'home': {
			init: function() {

			},
			finalize: function() {
				$('#front-page-video').mediaelementplayer({
					loop: true,
					autoplay: true
				});
				setTimeout( function(e){
					// $('#sbi_images').slick();
				}, 3000);
				// $('#front-page-video').fitVids();
			}
		},
    'post_type_archive_product': {
      init: function() {
         $(document).on('facetwp-refresh', function() {
           var filters = FWP.facets.menu;
           
           if(filters.length > 0) {
             var text = 'Shop / ' + filters.join(" / ");
           } else {
             var text = "Shop";
           } 
           // Retrieve shop title value
           var title_el = $('.shop-title');

           if(title_el.length) {
             title_el.text(text);
           }

         });

        // $(window).on('load', function() {
        //   $('#delivery-modal').modal();
        // });
      }
    },
		'blog': {
			init: function() {},
			finalize: function() {

				var mixer_blog_wrapper = mixitup('.blog--wrapper', {
					pagination: {
						limit: 6
					},
					templates: {
						pager: 
						'<button type="button" class="${classNames} mixitup-number" data-page="${pageNumber}">${pageNumber}</button>',
						pagerNext: '<button type="button" class="${classNames} next" data-page="next">Next <i class="fal fa-chevron-right"></i></button>',
						pagerPrev: '<button type="button" class="${classNames} prev" data-page="prev"><i class="fal fa-chevron-left"></i> Previous</button>'
					} 
				});
			}
		},
		'page_template_template_locate_a_dealer': {
			init: function(){
			},
			finalize: function() {
				if( typeof region ){

					if( region == "usa" ) {
					// $('#aus-map').hide();
					// $('#usa-map').show();
				} else {
					// $('#usa-map').hide();
					// $('#aus-map').show();
					
					}
				}
			}
 		},
		'woocommerce_checkout' :{
			init: function() {

			},
			finalize: function() {
				var n = document.querySelector('#billing_state').selectedIndex = "1";
				console.log(n);
				
			}
		 },
		// Home page
		'page_template_template_frequently_asked_questions': {
			init: function() {

				$('.faq-post__trigger').click(function(e) {
					e.preventDefault();
					var $this = $(this);

					$this.parent().toggleClass('active').find('.faq-post__bottom').slideToggle();
				});

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS

				var mixer_faqs_wrapper = mixitup('.faqs__wrapper');
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};



	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
